import React from "react";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import {
  useTranslation,
  TabSpinner,
  Typography,
  useNumberFormatter,
  useSession,
  EmptyState,
  SmileySad,
  ArrowCircleRightSolid,
  RefreshOutlined,
} from "@lumar/shared";
import { useProjectTasks } from "./data/useProjectTasks";
import { CircularProgress, Tab, Tabs, useTheme } from "@material-ui/core";
import { ArchivedTasksGrid } from "./archived-tasks/ArchivedTasksGrid";
import { SortableTasksTables } from "./tasks-table/SortableTasksTables";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import { Task } from "./data/types";
import { SearchWithDebounce } from "../_common/searchWithDebounce/SearchWithDebounce";
import { EditTaskDialog } from "./edit-task/EditTaskDialog";
import { LegacyTaskStatus, RoleCode } from "../graphql";
import { TasksTables } from "./tasks-table/TasksTable";
import { Routes } from "../_common/routing/routes";
import { useParams } from "react-router-dom";

export function ProjectTaskManager(): JSX.Element {
  const { t } = useTranslation("taskManager");
  const searchParams = useURLSearchParams();

  const tab = searchParams.get("tab") || "all";

  const {
    loading,
    error,
    tasks,
    archivedTaskCount,
    lastFinishedCrawlId,
    moveTask,
  } = useProjectTasks();

  return (
    <>
      <TopNavigation
        title={t("title")}
        breadcrumbs={[{ label: t("breadcrumb") }]}
        availableForShare
      />
      <Tabs
        value={tab}
        onChange={(_, value) => {
          searchParams.set("tab", value);
          searchParams.delete("pagination");
          searchParams.navigate();
        }}
        indicatorColor="primary"
      >
        <Tab
          value="all"
          label={
            <TabLabel
              label={t("allTasksTab")}
              loading={loading}
              value={error ? undefined : tasks.length}
            />
          }
          data-testid="task-manager-all-tasks"
        />
        <Tab
          value="archived"
          label={
            <TabLabel
              label={t("archivedTasksTab")}
              loading={loading}
              value={error ? undefined : archivedTaskCount}
            />
          }
          data-testid="task-manager-archived-tasks"
        />
      </Tabs>
      {tab === "all" && (
        <ProjectTasksTable
          loading={loading}
          error={error}
          tasks={tasks}
          lastFinishedCrawlId={lastFinishedCrawlId}
          moveTask={moveTask}
        />
      )}
      {tab === "archived" && <ArchivedTasksGrid />}
    </>
  );
}

function TabLabel({
  label,
  loading,
  value,
}: {
  label: string;
  loading: boolean;
  value?: number;
}): JSX.Element {
  const formatNumber = useNumberFormatter();

  return (
    <Typography variant="inherit">
      {label}{" "}
      {loading ? (
        <TabSpinner />
      ) : value !== undefined ? (
        `(${formatNumber(value)})`
      ) : null}
    </Typography>
  );
}

export function ProjectTasksTable({
  loading,
  error,
  tasks,
  lastFinishedCrawlId,
  moveTask,
}: {
  loading: boolean;
  error: boolean | undefined;
  tasks: Task[];
  lastFinishedCrawlId: string | undefined;
  moveTask: (
    taskId: string,
    status: LegacyTaskStatus,
    position: number,
  ) => void;
}): JSX.Element {
  const { t } = useTranslation(["common", "taskManager"]);
  const { hasSufficientRole } = useSession();
  const theme = useTheme();
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();

  const [search, setSearch] = React.useState("");
  const [selectedTask, setSelectedTask] = React.useState<Task | undefined>();

  const formattedSearch = search.toLocaleLowerCase();
  const filteredTasks = React.useMemo(
    () =>
      tasks.filter((task) =>
        task.title.toLocaleLowerCase().includes(formattedSearch),
      ),
    [tasks, formattedSearch],
  );

  const canEdit = hasSufficientRole(RoleCode.Editor);
  const lastCrawlLink =
    lastFinishedCrawlId &&
    Routes.Report.getUrl({
      accountId,
      projectId,
      crawlId: lastFinishedCrawlId,
      reportTemplateCode: "all_pages",
      reportTypeCode: "basic",
    });

  return (
    <div style={{ marginTop: 12 }}>
      <SearchWithDebounce
        debounceInMs={300}
        value={search}
        valueChangeCallbackFn={setSearch}
        placeholder={t("taskManager:table.searchPlaceholder")}
        style={{ marginBottom: 17 }}
      />
      {error ? (
        <EmptyState
          title={t("common:genericError")}
          description={t("taskManager:taskLoadError")}
          icon={<SmileySad style={{ color: theme.palette.red[400] }} />}
          actions={[
            {
              type: "button",
              title: t("common:reloadPage"),
              onClick: () => window.location.reload(),
              startIcon: <RefreshOutlined />,
            },
          ]}
          height={400}
        />
      ) : loading ? (
        <div>
          <CircularProgress
            aria-label={t("taskManager:table.projectTasksLoading")}
            style={{ marginTop: theme.spacing(2) }}
          />
        </div>
      ) : !tasks.length ? (
        <EmptyState
          title={t("taskManager:noTaskTitle")}
          description={t("taskManager:noTaskDescription")}
          actions={
            lastCrawlLink
              ? [
                  {
                    type: "internalLink",
                    title: t("taskManager:goToLatestCrawl"),
                    to: lastCrawlLink,
                    endIcon: <ArrowCircleRightSolid />,
                  },
                ]
              : []
          }
          height={400}
        />
      ) : (
        <>
          {canEdit ? (
            <SortableTasksTables
              tasks={filteredTasks}
              onTaskSelected={setSelectedTask}
              onTaskMove={moveTask}
            />
          ) : (
            <TasksTables
              tasks={filteredTasks}
              onTaskSelected={setSelectedTask}
            />
          )}
        </>
      )}
      {selectedTask && (
        <EditTaskDialog
          taskId={selectedTask.id}
          onClose={() => setSelectedTask(undefined)}
        />
      )}
    </div>
  );
}
