import React from "react";
import {
  ApolloError,
  getRawCrawlId,
  Snackbar,
  useTranslation,
} from "@lumar/shared";
import {
  LegacyTaskStatus,
  ProjectTasksQuery,
  useProjectTasksQuery,
  useUpdateTaskPositionMutation,
} from "../../graphql";
import { Task } from "./types";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

interface ProjectTasksData {
  loading: boolean;
  error: boolean | undefined;
  tasks: Task[];
  archivedTaskCount: number;
  lastFinishedCrawlId: string | undefined;
  moveTask: (
    taskId: string,
    status: LegacyTaskStatus,
    position: number,
  ) => void;
}

export function useProjectTasks(): ProjectTasksData {
  const { projectId } = useParams<{
    projectId: string;
  }>();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("common");

  const { loading, error, data } = usePaginatedProjectTasksQuery(projectId);

  const [tasks, updateTaskPosition] = useTasksWithPosition(data);

  const [updateTaskPositionQuery] = useUpdateTaskPositionMutation({
    onError: (error) =>
      enqueueSnackbar(
        <Snackbar title={t("genericError")} variant="error">
          {error.message}
        </Snackbar>,
      ),
  });

  return {
    loading,
    error: Boolean(error),
    tasks,
    archivedTaskCount: data?.getProject?.archivedTasks.totalCount ?? 0,
    lastFinishedCrawlId: data?.getProject?.lastFinishedCrawl
      ? getRawCrawlId(data.getProject.lastFinishedCrawl.id)
      : undefined,
    moveTask: (taskId, status, newPos) => {
      updateTaskPositionQuery({
        variables: {
          input: {
            legacyTaskId: taskId,
            status,
            position: newPos,
          },
        },
      });
      updateTaskPosition(taskId, status, newPos);
    },
  };
}

function usePaginatedProjectTasksQuery(projectId: string): {
  loading: boolean;
  error?: ApolloError;
  data?: ProjectTasksQuery;
} {
  const {
    data: projectTaskData,
    error,
    loading: queryLoading,
    fetchMore,
  } = useProjectTasksQuery({
    variables: { projectId },
    fetchPolicy: "cache-and-network",
  });

  const pageInfo = projectTaskData?.getProject?.legacyTasks?.pageInfo;
  if (pageInfo?.hasNextPage) {
    fetchMore({ variables: { cursor: pageInfo.endCursor } });
  }

  // Note: The API does not allow requesting all the tasks at once. To prevent
  // returning partial data, it only returns the data when all the pages have arrived - Csaba
  const dataRef = React.useRef<ProjectTasksQuery | undefined>(projectTaskData);
  if (!pageInfo?.hasNextPage) dataRef.current = projectTaskData;

  return {
    loading: Boolean(queryLoading || pageInfo?.hasNextPage),
    error,
    data: dataRef.current,
  };
}

type TaskWithPosition = Omit<Task, "position"> & { position: number };
type UpdateTaskPositionFn = (
  taskId: string,
  status: LegacyTaskStatus,
  newPos: number,
) => void;

// This function uses a local state to store the updated position instead of relying
// on the cache. The reason for this is that there is no guarantee the mutation results
// will return in the same order they were called. If the mutations return out of order,
// it could result in the cache being overwritten with incorrect values.
function useTasksWithPosition(
  data: ProjectTasksQuery | undefined,
): [TaskWithPosition[], UpdateTaskPositionFn] {
  const [positions, setPositions] = React.useState<
    Record<string, { position: number; status?: LegacyTaskStatus | null }>
  >({});

  const tasks = React.useMemo(() => {
    const tasks = data?.getProject?.legacyTasks.edges ?? [];
    const maxPos = Math.max(0, ...tasks.map((x) => x.node.position ?? 0));

    const [tasksWithPosition] = tasks.reduce<
      [(Omit<Task, "position"> & { position: number })[], number]
    >(
      ([tasks, max], task) => [
        [
          ...tasks,
          {
            ...task.node,
            position:
              positions[task.node.id]?.position ?? task.node.position ?? max,
            status: positions[task.node.id]?.status ?? task.node.status,
          },
        ],
        typeof task.node.position !== "number" ? max + 1 : max,
      ],
      [[], maxPos + 1],
    );

    // eslint-disable-next-line fp/no-mutating-methods
    return tasksWithPosition.sort((a, b) => a.position - b.position);
  }, [data?.getProject?.legacyTasks.edges, positions]);

  const updateTaskPosition: UpdateTaskPositionFn = (taskId, status, newPos) => {
    const task = tasks.find((x) => x.id === taskId);
    if (!data || !task) return;

    const oldPos = task.position;
    setPositions(
      Object.fromEntries(
        tasks.map<
          [string, { position: number; status?: LegacyTaskStatus | null }]
        >((task) => {
          if (task.id === taskId) {
            return [task.id, { position: newPos, status }];
          }

          if (oldPos < newPos) {
            if (task.position > oldPos && task.position <= newPos) {
              return [
                task.id,
                { position: task.position - 1, status: task.status },
              ];
            }
          } else {
            if (task.position >= newPos && task.position < oldPos) {
              return [
                task.id,
                { position: task.position + 1, status: task.status },
              ];
            }
          }

          return [task.id, { position: task.position, status: task.status }];
        }),
      ),
    );
  };

  return [tasks, updateTaskPosition];
}
