/* eslint-disable react/display-name */
import React from "react";
import { Theme, Typography } from "@material-ui/core";
import { TFunction } from "i18next";

import {
  ChartConfigItem,
  LinkData,
  VisualisationTypes,
} from "../types/ChartConfig";
import { ModuleCode, ReportTrendItem, ReportTypeCode } from "../../../graphql";

import { Routes } from "../../routing/routes";
import { getRawCrawlId, DateFormatter, Trans } from "@lumar/shared";
import { ReportInResponse } from "../utils/generateChartQuery";
import { RunNewCrawlMessage } from "../components/chart-messages/RunNewCrawlMessage";

export function getAccessibilityCategoriesTrend(
  t: TFunction<"charts">,
  _: Theme,
  formatter: DateFormatter,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.Line,
    additionalVisualisationTypes: [
      VisualisationTypes.Area,
      VisualisationTypes.StackedArea,
      VisualisationTypes.FullStackedArea,
    ],
    supportedModules: [ModuleCode.Accessibility],
    title: () => t("accessibilityCategoriesTrend.title"),
    description: () => (
      <Trans
        ns="charts"
        i18nKey="accessibilityCategoriesTrend.description"
        components={[
          <Typography key="0" paragraph variant="inherit" />,
          <Typography key="1" variant="inherit" />,
        ]}
      />
    ),
    reports: () => ({
      filter: {
        reportTypeCode: ReportTypeCode.Basic,
        reportTemplateCode: [
          "aria_a",
          "color",
          "forms",
          "keyboard_a",
          "language",
          "name_role_value_a",
          "parsing_a",
          "structure",
          "tables_a",
          "text_alternatives_a",
          "time_and_media_a",
          "semantics",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
        reportTrend: {
          crawlId: true,
          createdAt: true,
          basic: true,
        },
      },
    }),
    bindings: {
      series: (
        reports: ReportInResponse[],
        { accountId, projectId, segmentId }: LinkData,
      ) =>
        reports.map((report) => {
          return {
            name: report.reportTemplate.name,
            reportTemplateCode: report.reportTemplate.code,
            data: (report.reportTrend ?? [])
              .filter(
                (
                  trend,
                ): trend is ReportTrendItem & {
                  createdAt: string;
                } => !!trend?.createdAt,
              )
              .map((trend) => {
                const createdAt = new Date(trend.createdAt);
                return {
                  x: createdAt,
                  y: trend.basic ?? 0,
                  url: Routes.Report.getUrl({
                    accountId,
                    projectId,
                    crawlId: getRawCrawlId(String(trend.crawlId)),
                    segmentId,
                    reportTemplateCode: report.reportTemplate.code,
                    reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
                  }),
                  additionalTooltipLines: [
                    formatter(createdAt, {
                      dateStyle: "medium",
                      timeStyle: "short",
                    }),
                  ],
                };
              }),
          };
        }),
    },
    incompleteSeriesTemplate: (
      <RunNewCrawlMessage message={t("runNewCrawlForTrendMessage")} />
    ),
    testAttributePrefix: "a11y-categories-trend",
  };
}
