import React from "react";
import { alpha, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Highcharts, { PlotAreaOptions } from "highcharts";
import { HighchartsChart } from "../../_common/charts/components/HighchartsChart";
import { useInView } from "react-intersection-observer";
import { useDateFormatter } from "@lumar/shared";

interface TaskTrendProps {
  trend?:
    | {
        identified?: number | null;
        crawlFinishedAt: string;
      }[]
    | null
    | undefined;
  identified?: number | null;
}

export const TaskTrend = React.memo(function TaskTrend(
  props: TaskTrendProps,
): JSX.Element {
  const { ref, inView } = useInView({
    delay: 0,
    rootMargin: "500px 0px 500px 0px",
    threshold: 0,
    fallbackInView: true,
    triggerOnce: true,
  });

  return <div ref={ref}>{inView && <TaskTrendInner {...props} />}</div>;
});

const noTrendData = [
  ...[
    { x: 0, y: 0.6 },
    { x: 2.1, y: 0.6 },
    { x: 2.9, y: 0.96 },
    { x: 3.07, y: 0.6 },
    { x: 5.1, y: 0.6 },
    { x: 5.48, y: 0.77 },
    { x: 5.92, y: 0.6 },
    { x: 6.58, y: 0.77 },
    { x: 7.03, y: 0.6 },
    { x: 7.73, y: 0.6 },
    { x: 8.11, y: 1 },
    { x: 8.11, y: 1 },
    { x: 8.76, y: 1 },
    { x: 9.35, y: 1.25 },
    { x: 9.87, y: 1 },
    { x: 10.45, y: 1.25 },
    { x: 10.94, y: 1 },
    { x: 11.45, y: 0.8 },
    { x: 11.91, y: 1.51 },
    { x: 12.56, y: 1.28 },
    { x: 13.25, y: 1.91 },
    { x: 13.96, y: 1.91 },
  ],
];

const noTrendMax = 4.43;

function TaskTrendInner({ trend, identified }: TaskTrendProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");
  const theme = useTheme();

  // eslint-disable-next-line fp/no-mutating-methods
  const data = (trend ?? [])
    .filter((x) => typeof x.identified === "number")
    .map((x) => ({ ...x, crawlDate: new Date(x.crawlFinishedAt) }))
    .sort((a, b) => a.crawlDate.getTime() - b.crawlDate.getTime())
    .splice(-30)
    .map<[number, number]>((item) => [
      item.crawlDate.getTime(),
      item.identified ?? 0,
    ]);

  if (data.length < 2) {
    return (
      <div className={classes.noTrend}>
        <Typography className={classes.noTrendLabel}>{t("noTrend")}</Typography>
        <div className={classes.noTrendChart}>
          <TrendChart
            data={noTrendData}
            max={noTrendMax}
            colors={[theme.palette.grey[600]]}
            fillColor={alpha(theme.palette.grey[200], 0.5)}
          />
        </div>
      </div>
    );
  }

  const trendMax = Math.max(...data.map((x) => x[1]), identified ?? 0);

  return (
    <TrendChart
      data={data}
      max={trendMax}
      colors={[theme.palette.grey[600]]}
      fillColor={alpha(theme.palette.grey[200], 0.5)}
    />
  );
}

interface SparklinesMiniChartProps {
  data: [number, number][] | { x: number; y: number }[];
  max?: number;
  colors?: string[];
  fillColor?: PlotAreaOptions["fillColor"];
}

function TrendChart({
  data,
  max,
  colors,
  fillColor,
}: SparklinesMiniChartProps): JSX.Element {
  const formatDate = useDateFormatter();
  const { t } = useTranslation("taskManager");

  const options: Highcharts.Options = {
    chart: {
      type: "area",
      animation: false,
      width: 160,
      height: 40,
      backgroundColor: "transparent",
      margin: [2, 2, 2, 2],
    },
    colors: colors,
    plotOptions: {
      series: {
        lineWidth: 1,
      },
      area: {
        fillColor: fillColor,
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        fillOpacity: 0.2,
        data: data,
        enableMouseTracking: false,
        animation: false,
        type: "area",
        marker: {
          radius: 1.5,
          enabled: true,
        },
        accessibility: {
          point: {
            descriptionFormatter: (e) =>
              t("taskTrendPointAnnouncement", {
                date: formatDate(new Date(e.x), {
                  dateStyle: "medium",
                  timeStyle: "short",
                }),
                value: e.y,
              }),
          },
        },
      },
    ],
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      max: max,
      min: 0,
    },
    legend: {
      enabled: false,
    },
  };

  return <HighchartsChart options={options} />;
}

const useStyles = makeStyles((theme) => ({
  noTrend: {
    position: "relative",
  },
  noTrendLabel: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(13),
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noTrendChart: {
    opacity: 0.3,
  },
}));
