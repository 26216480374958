import { useAccountTasksQuery } from "../../graphql";
import { Task } from "./types";
import { useParams } from "react-router-dom";

interface AccountTasksData {
  tasks: Task[];
  error: boolean | undefined;
  loading: boolean;
  allTaskCount: number;
  hasMore: boolean;
  fetchMore: () => void;
}

export function useAccountTasks({
  search,
}: {
  search: string;
}): AccountTasksData {
  const { accountId } = useParams<{ accountId: string }>();

  const { data, error, loading, fetchMore } = useAccountTasksQuery({
    variables: {
      accountId,
      filter: {
        fixedAt: { isNull: true },
        ...(search ? { title: { contains: search } } : {}),
      },
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    error: Boolean(error),
    tasks: data?.getAccount?.legacyTasks.edges.map((x) => x.node) || [],
    allTaskCount: data?.getAccount?.allTasks.totalCount ?? 0,
    hasMore: data?.getAccount?.legacyTasks.pageInfo.hasNextPage || false,
    fetchMore: () =>
      fetchMore({
        variables: { after: data?.getAccount?.legacyTasks.pageInfo.endCursor },
      }),
  };
}
