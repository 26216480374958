import {
  ApolloCache,
  ApolloError,
  ExcludeEmpty,
  Snackbar,
  useTranslation,
} from "@lumar/shared";
import {
  AccountTasksDocument,
  AccountTasksQuery,
  GetTaskForEditQuery,
  GetTaskTrendsQuery,
  LegacyTaskPriority,
  LegacyTaskStatus,
  useDeleteTaskMutation,
  useGetTaskForEditQuery,
  useGetTaskTrendsLazyQuery,
  useUpdateTaskMutation,
} from "../../graphql";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

export interface FormValues {
  title: string;
  description: string | undefined;
  howToFix: string | undefined;
  assignedTo: string[];
  priority: LegacyTaskPriority;
  status: LegacyTaskStatus;
  deadline: Date | null;
}

export interface CreateTaskFormValuesProps {
  taskId: string;
  accountSearch?: string;
}

export interface CreateTaskFormValuesResult {
  loading: boolean;
  error?: string;
  values: FormValues;
  task?: ExcludeEmpty<GetTaskForEditQuery["getLegacyTask"]>;
  taggedTrendLoading: boolean;
  taggedTrends: GetTaskTrendsQuery | undefined;
  updateTask: (values: FormValues) => Promise<boolean>;
  deleteTask: () => Promise<boolean>;
  archiveTask: () => Promise<boolean>;
}

export function useEditTaskFormValues({
  taskId,
  accountSearch,
}: CreateTaskFormValuesProps): CreateTaskFormValuesResult {
  const { t } = useTranslation("taskManager");
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = useParams<{ accountId: string }>();

  const [
    fetchTrend,
    { data: trendData, loading: trendLoading, error: trendError },
  ] = useGetTaskTrendsLazyQuery();

  const { loading, error, data } = useGetTaskForEditQuery({
    variables: {
      taskId,
    },
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      const task = data?.getLegacyTask ?? undefined;
      const lastFinishedCrawlId = task?.project.lastFinishedCrawl?.id;

      const unresolvedReportTemplateCode =
        task?.customReportTemplates.nodes.find((x) =>
          x.code.startsWith("task_unresolved_"),
        )?.code;
      const resolvedReportTemplateCode = task?.customReportTemplates.nodes.find(
        (x) => x.code.startsWith("task_resolved_"),
      )?.code;
      const allReportTemplateCode = task?.customReportTemplates.nodes.find(
        (x) => x.code.startsWith("task_all_"),
      )?.code;

      if (
        lastFinishedCrawlId &&
        unresolvedReportTemplateCode &&
        resolvedReportTemplateCode &&
        allReportTemplateCode
      ) {
        fetchTrend({
          variables: {
            crawlId: lastFinishedCrawlId,
            unresolvedReportTemplateCode,
            resolvedReportTemplateCode,
            allReportTemplateCode,
          },
        });
      }
    },
  });

  const [updateTask] = useUpdateTaskMutation({
    onError: (error) =>
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("editDialog.saveError", { message: error.message })}
        />,
      ),
  });

  const [deleteTask] = useDeleteTaskMutation({
    onError: (error) =>
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("actionsMenu.removeError", { message: error.message })}
        />,
      ),
  });

  return {
    loading,
    error: getErrorMessage(error ?? trendError),
    values: getValues(data),
    task: data?.getLegacyTask ?? undefined,
    taggedTrendLoading: trendLoading,
    taggedTrends: trendData,
    updateTask: async (values) => {
      const result = await updateTask({
        variables: {
          input: {
            legacyTaskId: taskId,
            title: values.title,
            description: values.description,
            howToFix: values.howToFix,
            assignedTo: values.assignedTo,
            priority: values.priority,
            status: values.status,
            deadlineAt: values.deadline ? values.deadline.toISOString() : null,
          },
        },
      });

      return Boolean(result.data);
    },
    deleteTask: async () => {
      const result = await deleteTask({
        variables: {
          taskId,
        },
        refetchQueries: ["ProjectTasks", "UnresolvedProjectTasksCountForChip"],
        awaitRefetchQueries: true,
        update: (cache) =>
          removeTaskFromAccountTasks(cache, accountId, accountSearch, taskId),
      });

      return Boolean(result.data);
    },
    archiveTask: async () => {
      const result = await updateTask({
        variables: {
          input: {
            legacyTaskId: taskId,
            fixedAt: new Date().toISOString(),
            status: LegacyTaskStatus.Done,
          },
        },
        refetchQueries: ["ProjectTasks", "UnresolvedProjectTasksCountForChip"],
        awaitRefetchQueries: true,
        update: (cache) =>
          removeTaskFromAccountTasks(cache, accountId, accountSearch, taskId),
      });

      return Boolean(result.data);
    },
  };
}

function getValues(data?: GetTaskForEditQuery): FormValues {
  const task = data?.getLegacyTask ?? undefined;

  return {
    title: task?.title ?? "",
    description: task?.description ?? undefined,
    howToFix: task?.howToFix ?? "",
    assignedTo: task?.assignedTo ?? [],
    priority: task?.priority ?? LegacyTaskPriority.Note,
    status: task?.status ?? LegacyTaskStatus.Backlog,
    deadline: task?.deadlineAt ? new Date(task.deadlineAt) : null,
  };
}

function getErrorMessage(error?: ApolloError): string | undefined {
  if (error?.graphQLErrors[0]) return error.graphQLErrors[0].message;
  if (error?.clientErrors?.[0]) return error.clientErrors[0].message;
  if (error?.protocolErrors?.[0]) return error.protocolErrors[0].message;
  return error?.message;
}

function removeTaskFromAccountTasks(
  cache: ApolloCache<unknown>,
  accountId: string,
  search: string | undefined,
  taskId: string,
): void {
  const cachedData: AccountTasksQuery | null = cache.readQuery({
    query: AccountTasksDocument,
    variables: {
      accountId,
      filter: {
        fixedAt: { isNull: true },
        ...(search ? { title: { contains: search } } : {}),
      },
    },
  });

  if (!cachedData?.getAccount) return;

  const taskFound = Boolean(
    cachedData.getAccount.legacyTasks.edges.find((x) => x.node.id === taskId),
  );

  cache.writeQuery({
    query: AccountTasksDocument,
    variables: {
      accountId,
      filter: {
        fixedAt: { isNull: true },
        ...(search ? { title: { contains: search } } : {}),
      },
    },
    data: {
      ...cachedData,
      getAccount: cachedData && {
        ...cachedData.getAccount,
        legacyTasks: cachedData.getAccount.legacyTasks && {
          ...cachedData.getAccount.legacyTasks,
          edges:
            cachedData.getAccount.legacyTasks.edges &&
            cachedData.getAccount.legacyTasks.edges.filter(
              (x) => x.node.id !== taskId,
            ),
        },
        allTasks: cachedData.getAccount.allTasks && {
          ...cachedData.getAccount.allTasks,
          totalCount:
            cachedData.getAccount.allTasks.totalCount - (taskFound ? 1 : 0),
        },
      },
    },
  });
}
